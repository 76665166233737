import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import moment from 'moment-timezone';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { TimezoneOption } from '@models/timezone/timezone-option.model';

@Component({
  selector: 'app-timezone-select',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule],
  templateUrl: './timezone-select.component.html',
  styleUrl: './timezone-select.component.scss',
})
export class TimezoneSelectComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() selectedFormControlName: string = '';

  items: TimezoneOption[] = [];
  filteredItems: TimezoneOption[] = [];

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.items = moment.tz
      .names()
      .reduce((zones: TimezoneOption[], tz) => {
        const offset = moment.tz(tz).utcOffset();

        zones.push(
          new TimezoneOption({
            name: tz,
            offset: offset,
          }),
        );

        return zones;
      }, [])
      .sort((a, b) => {
        return a.offset - b.offset;
      });
    this.filteredItems = this.items;
  }

  filter(reset = false) {
    if (reset) {
      this.filteredItems = this.items.slice();
      return;
    }

    const filterValue = this.input?.nativeElement.value.toLowerCase();
    if (filterValue) {
      this.filteredItems = this.items.filter((option) => {
        return (
          option
            .getDisplayFnName()
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) >= 0
        );
      });
    } else {
      this.filteredItems = this.items.slice();
    }
  }

  checkValue() {
    setTimeout(() => {
      let value = this.form.controls[this.selectedFormControlName].value;
      if (value) {
        value = value.getDisplayFnName();
      }

      const filterValue = this.input?.nativeElement.value.toLowerCase();

      if (
        typeof filterValue === 'string' &&
        (!value || value.toLowerCase() !== filterValue)
      ) {
        this.form.controls[this.selectedFormControlName].setValue(null);
        this.form.controls[
          this.selectedFormControlName
        ].updateValueAndValidity();
      }
    }, 100);
  }

  displayFn(item: TimezoneOption | null): string {
    if (item) {
      return item.getDisplayFnName();
    }

    return '';
  }
}
