<mat-form-field appearance="outline" class="w-100 select-wrapper" [formGroup]="form">
  <mat-label>{{ "APP.CREATE_EVENT.CHOOSE_TIMEZONE" | translate }}</mat-label>
  <input
    #input
    type="text"
    placeholder="{{ 'APP.TYPE_IN_OR_SELECT' | translate }}"
    matInput
    [matAutocomplete]="auto"
    [formControlName]="selectedFormControlName"
    (input)="filter()"
    (focus)="filter(true)"
    (blur)="checkValue()"
  />
  <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>

  <mat-autocomplete
    #auto="matAutocomplete"
    requireSelection
    [displayWith]="displayFn.bind(this)"
  >
    @for (item of filteredItems; track item.name) {
      <mat-option [value]="item" class="timezone-option">
        <div class="timezone-option-display">
          <div>{{ item.getDisplayName() }}</div>
          <div class="timezone-offset">GMT{{ item.getOffsetDisplay() }}</div>
        </div>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
